:root {
  --midnight-green-eagle: #0f4354;
  --burlywood-light: #f0c380;
  --burlywood-dark: #f3c36f;
  --goldenrod: #ffbf00;
  --alice-blue: #dde7ed;
  --light-moose-green: #cbddda;
  --medium-sea-green: #4dbd90;
  --cornflower-blue: #489fd9;
  --light-slate-gray: #8668ab;
  --gainsboro: #dfe8e4;
}

.w-pagination-wrapper {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.w-pagination-previous {
  color: #333;
  background-color: #fafafa;
  border: 1px solid #ccc;
  border-radius: 2px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 9px 20px;
  font-size: 14px;
  display: block;
}

.w-pagination-previous-icon {
  margin-right: 4px;
}

.w-pagination-next {
  color: #333;
  background-color: #fafafa;
  border: 1px solid #ccc;
  border-radius: 2px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 9px 20px;
  font-size: 14px;
  display: block;
}

.w-pagination-next-icon {
  margin-left: 4px;
}

body {
  color: #333;
  flex-direction: column;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  line-height: 22px;
  display: flex;
}

h1 {
  margin-top: 0;
  margin-bottom: 19px;
  font-size: 38px;
  font-weight: 500;
  line-height: 44px;
}

h2 {
  text-align: left;
  margin-top: 40px;
  margin-bottom: 20px;
  font-size: 30px;
  font-weight: 400;
  line-height: 36px;
}

h3 {
  direction: ltr;
  text-transform: none;
  border: 1px #000;
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 22px;
  font-weight: 500;
  line-height: 30px;
}

h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-decoration: none;
  display: block;
}

h5 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}

h6 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
}

p {
  margin-top: 0;
  margin-bottom: 20px;
  line-height: 26px;
}

a {
  text-decoration: underline;
}

strong {
  font-weight: bold;
}

.button {
  color: #000;
  text-align: center;
  background-color: #d9dcd6;
  border-radius: 3px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 14px;
  padding: 13px 23px;
  font-family: Roboto Slab, sans-serif;
  text-decoration: none;
  transition: background-color 0.3s, box-shadow 0.3s, color 0.3s;
  display: inline-block;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.12);
}

.button:hover {
  background-color: #489fd9;
}

.button.hollow {
  float: left;
  border: 2px solid var(--midnight-green-eagle);
  box-shadow: none;
  color: var(--midnight-green-eagle);
  text-shadow: none;
  -webkit-text-fill-color: inherit;
  background-color: rgba(255, 255, 255, 0.9);
  background-clip: border-box;
  border-radius: 2px;
  flex-flow: row;
  flex: 0 auto;
  justify-content: center;
  align-items: stretch;
  width: auto;
  min-width: 25em;
  padding-right: 23px;
  font-family: Roboto, sans-serif;
  font-size: 1.1em;
  line-height: 1.2em;
  display: block;
  position: relative;
}

.button.hollow:hover {
  color: #fff;
  text-shadow: none;
  background-color: #489fd9;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.12), inset 0 0 0 2px #489fd9;
}

.button.in-pricing {
  float: none;
  clear: left;
  background-color: var(--midnight-green-eagle);
  color: #fff;
  object-fit: contain;
  object-position: 50% 50%;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: 20px auto 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: Roboto, sans-serif;
  display: block;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: 0%;
  overflow: visible;
}

.button.in-pricing.basefeebutton {
  background-color: var(--midnight-green-eagle);
  color: #fff;
  object-fit: contain;
  width: 80%;
  margin-top: 16px;
  margin-left: auto;
  margin-right: auto;
  font-family: Roboto, sans-serif;
  display: block;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.button.in-pricing.centered {
  width: 16rem;
  max-width: 80%;
  margin: auto;
  display: block;
}

.button.button-newsletter {
  background-color: var(--midnight-green-eagle);
  color: #fff;
  min-width: 250px;
  font-family: Roboto, sans-serif;
}

.button.free-flow-button-center-midnigh-green {
  float: none;
  clear: left;
  background-color: var(--midnight-green-eagle);
  color: #fff;
  object-fit: scale-down;
  object-position: 50% 50%;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: center;
  align-items: center;
  width: 16rem;
  max-width: 80%;
  margin: 20px auto 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: Roboto, sans-serif;
  display: block;
  position: static;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: 0%;
  overflow: visible;
}

.button.free-flow-button-center-midnigh-green.basefeebutton {
  background-color: var(--midnight-green-eagle);
  color: #fff;
  object-fit: contain;
  width: 80%;
  margin-top: 16px;
  margin-left: auto;
  margin-right: auto;
  font-family: Roboto, sans-serif;
  display: block;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.button.free-flow-button-center-midnigh-green.centered {
  width: 16rem;
  max-width: 80%;
  margin: auto;
  display: block;
}

.button.framed-transparent {
  border: 1px solid var(--midnight-green-eagle);
  box-shadow: none;
  color: var(--midnight-green-eagle);
  background-color: rgba(255, 255, 255, 0);
  font-family: Roboto, sans-serif;
}

.logo-text {
  color: #0f4354;
  letter-spacing: 0;
  text-indent: 0;
  text-transform: uppercase;
  text-shadow: none;
  align-items: center;
  margin-top: 0;
  margin-left: 15px;
  font-family: Roboto, sans-serif;
  font-size: 30px;
  font-weight: 500;
  display: flex;
}

.logo-text.footer {
  color: #e6eaed;
  margin-top: 0;
  margin-bottom: 19px;
}

.navigation {
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0);
  background-image: linear-gradient(#fff, #fff);
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  max-height: 74px;
  margin-left: auto;
  margin-right: auto;
  transition: opacity 0.2s;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  overflow: visible;
  box-shadow: 0 1px rgba(0, 0, 0, 0.13);
}

.nav-link {
  color: #0f4354;
  text-shadow: none;
  background-color: rgba(0, 0, 0, 0);
  padding: 26px 17px;
  transition: box-shadow 0.5s, color 0.3s;
  box-shadow: inset 0 3px rgba(0, 0, 0, 0);
}

.nav-link:hover {
  box-shadow: inset 0 3px rgba(181, 181, 181, 0.5);
}

.nav-link.w--current {
  color: #4dbd90;
  box-shadow: inset 0 3px #4dbd90;
}

.nav-link.border {
  border: 1px #000;
}

.nav-link.color-white {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
}

.section {
  opacity: 1;
  padding-top: 70px;
  padding-bottom: 70px;
}

.section.main {
  background-color: var(--burlywood-light);
  background-image: url('../images/p-light.png'),
    linear-gradient(
      173deg,
      var(--burlywood-dark),
      var(--goldenrod) 95%,
      var(--goldenrod)
    );
  color: #fff;
  text-align: left;
  text-shadow: 0 1px rgba(0, 0, 0, 0.23);
  object-fit: fill;
  background-position: 50%, 0 0;
  background-repeat: no-repeat, repeat;
  background-size: 50vw, auto;
  flex: 1;
  min-height: 100vh;
  margin-top: 75px;
  padding-top: 0;
  padding-bottom: 75px;
  transition: opacity 0.2s;
  overflow: visible;
}

.section.main.lp-normal {
  background-image: url('../images/pexels-amina-filkins-5560553_small.jpg'),
    linear-gradient(
      173deg,
      var(--burlywood-dark),
      var(--goldenrod) 95%,
      var(--goldenrod)
    );
  object-fit: contain;
  background-position: 50%, 0 0;
  background-size: cover, auto;
  flex: 0 70em;
  align-self: auto;
  min-height: 10em;
}

.section.main.lp-normal:hover {
  font-weight: 700;
}

.section.main.lp-dsb-2 {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.section.footer {
  opacity: 1;
  color: #e4e8eb;
  background-color: #0f4354;
  flex-direction: column;
  flex: 0 auto;
  justify-content: flex-start;
  align-self: auto;
  min-width: auto;
  min-height: auto;
  margin-top: auto;
  padding-top: 42px;
  padding-bottom: 42px;
  display: block;
  position: static;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: 0%;
  overflow: visible;
}

.section.footer.copyright {
  color: #939596;
  background-color: #1c1d1f;
  padding-top: 19px;
  padding-bottom: 19px;
  font-size: 14px;
}

.section.purple {
  background-color: var(--alice-blue);
  position: relative;
}

.section.clients {
  background-color: #a1bfd4;
  padding-top: 0;
  padding-bottom: 0;
}

.section.press {
  background-color: #a1bfd4;
  padding-top: 29px;
  padding-bottom: 38px;
}

.section.secondarycolor {
  background-color: #cbddda;
}

.slider {
  background-color: #303030;
  height: 360px;
  display: none;
  overflow: visible;
}

.slide {
  padding-top: 64px;
  padding-bottom: 64px;
}

.slide._1 {
  background-color: #00ad6e;
}

.slide._2 {
  background-color: #00bdd6;
}

.slide._3 {
  background-color: #ccbe00;
}

.main-subtitle {
  color: var(--midnight-green-eagle);
  text-shadow: none;
  margin-top: 10px;
  margin-bottom: 0;
  margin-right: 26px;
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
}

.footer-slogan {
  color: #939596;
  margin-bottom: 11px;
}

.social-icon {
  opacity: 0.41;
  margin-right: 11px;
  transition: opacity 0.3s;
}

.social-icon:hover {
  opacity: 1;
}

.footer-title {
  text-transform: uppercase;
  margin-top: 0;
  font-family: Roboto Condensed, sans-serif;
  font-weight: 400;
}

.page-link {
  color: #489fd9;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
  transition: color 0.3s, border 0.3s;
}

.page-link:hover {
  border-bottom-color: #489fd9;
}

.page-link.in-footer {
  color: #939596;
  font-size: 14px;
  display: block;
}

.page-link.in-footer:hover {
  color: #d2d7d9;
  border-bottom-color: rgba(0, 0, 0, 0);
}

.newsletter-field {
  float: left;
  background-color: #e8e8e8;
  border: 1px solid rgba(0, 0, 0, 0);
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  width: 60%;
  height: 40px;
}

.newsletter-field:focus {
  border-width: 1px;
  border-color: #09f;
  box-shadow: 0 0 6px #489fd9;
}

.newsletter-button {
  float: left;
  color: #d5d8db;
  background-color: #5f6166;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  width: 40%;
  height: 40px;
  padding-top: 9px;
  padding-bottom: 9px;
  transition: all 0.5s;
}

.newsletter-button:hover {
  color: #fff;
  background-color: #489fd9;
}

.newsletter-form {
  width: 70%;
}

.brand-link {
  object-fit: fill;
  flex-flow: row;
  justify-content: center;
  align-items: stretch;
  height: 100%;
  min-height: 22px;
  margin-top: 13px;
  text-decoration: none;
  display: flex;
  position: static;
}

.brand-link.w--current {
  object-fit: none;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  margin: auto;
  display: flex;
  position: absolute;
  top: auto;
  overflow: visible;
}

.success-message {
  background-color: #5f6166;
}

.main-heading {
  color: var(--midnight-green-eagle);
  letter-spacing: 2px;
  text-indent: 0;
  text-transform: uppercase;
  text-shadow: none;
  margin-top: -5px;
  margin-bottom: 20px;
  font-size: 38px;
  font-weight: 700;
  line-height: 44px;
}

.main-heading.smaller-bottom-margin {
  color: #fff;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  object-fit: fill;
  background-color: #d782ff;
  margin: 10px;
  padding: 10px 20px;
  font-size: 3em;
  font-style: normal;
  font-weight: 700;
  line-height: 0.8em;
  display: inline-block;
  overflow: visible;
}

.main-heading.smaller-bottom-margin.text-align-center {
  text-align: center;
}

.main-heading.smaller-bottom-margin.two-part-top {
  float: none;
  text-align: center;
  white-space: break-spaces;
  object-fit: fill;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 3.2em;
  font-weight: 900;
  line-height: 1em;
  display: inline-block;
}

.main-heading.smaller-bottom-margin.two-part-bottom {
  background-color: #d782ff;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 36px;
  line-height: 36px;
  display: inline-block;
}

.main-heading.smaller-bottom-margin.align-left {
  float: none;
  clear: none;
  text-align: center;
  object-fit: fill;
  justify-content: center;
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 3em;
  line-height: 0.8em;
  display: inline-block;
}

.section-subtitle {
  text-align: center;
  letter-spacing: normal;
  text-indent: 8px;
  white-space: normal;
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  display: none;
}

.bold-text {
  color: #333;
  font-size: 18px;
  font-weight: 400;
}

.bold-text.muted {
  color: #333;
}

.bold-text.subtext {
  font-size: 14px;
  line-height: 18px;
}

.plan-wrapper {
  color: #2b2b2b;
  background-color: #fff;
  border-radius: 3px;
  height: 500px;
  padding: 17px 23px;
}

.price-point {
  margin-bottom: 8px;
  font-size: 14px;
}

.price-accent {
  color: #489fd9;
  letter-spacing: 0;
  font-size: 20px;
}

.addl-feature {
  text-indent: 0;
  margin-bottom: 14px;
  font-size: 14px;
  line-height: 22px;
}

.addl-feature.inactive {
  color: #c7c5c5;
  text-decoration: line-through;
}

.addl-feature.featurenotavailable {
  color: #2b2b2b;
  font-style: normal;
  text-decoration: none;
}

.divider {
  background-color: #dbdbdb;
  width: 50%;
  height: 1px;
  margin-top: 13px;
  margin-bottom: 12px;
}

.additional-contact {
  text-align: center;
  margin-top: 21px;
  font-size: 18px;
}

.additional-contact.subtext {
  margin-bottom: 60px;
  font-size: 14px;
  line-height: 18px;
}

.feature {
  text-align: left;
  margin-top: 30px;
}

.feature-row {
  margin-bottom: 40px;
}

.testimonial-slider {
  background-color: rgba(0, 0, 0, 0);
  height: auto;
  margin-top: 45px;
}

.testimony-slide {
  text-align: center;
  padding-top: 0;
  padding-left: 10%;
  padding-right: 10%;
}

.testimony-text {
  margin-bottom: 21px;
  font-family: Roboto Slab, sans-serif;
  font-size: 25px;
  font-weight: 300;
  line-height: 31px;
}

.slide-nav {
  font-size: 10px;
  display: none;
}

.person {
  border-radius: 100%;
  width: 70px;
  margin-bottom: 13px;
}

.name {
  font-weight: 500;
}

.slider-arrow {
  color: #bdbdbd;
  width: 50px;
  font-size: 32px;
  transition: color 0.3s;
}

.slider-arrow:hover {
  color: #000;
}

.feature-icon {
  opacity: 1;
  color: #0f4354;
  text-align: center;
  mix-blend-mode: normal;
  background-color: rgba(0, 0, 0, 0);
  margin-bottom: 12px;
}

.main-feature-group {
  text-align: left;
  margin-top: 30px;
  font-size: 1em;
  line-height: 1em;
}

.small-features-row {
  margin-top: 0;
  margin-bottom: 40px;
}

.client-logo {
  width: 16.6%;
  display: none;
}

.small-text {
  color: #a1bfd4;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
}

.div-block {
  justify-content: center;
  display: flex;
}

.logo {
  width: 14%;
  margin-left: 2%;
  margin-right: 2%;
  display: none;
}

.column {
  z-index: 100;
  background-color: rgba(230, 230, 230, 0.53);
  padding: 20px;
  display: block;
}

.container {
  text-align: center;
  margin-top: 0;
  position: relative;
}

.columns {
  margin-bottom: 187px;
  padding-right: 0;
  position: relative;
  top: 100px;
}

.utility-page-wrap {
  justify-content: center;
  align-items: center;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  max-height: 100%;
  display: flex;
}

.utility-page-content {
  text-align: center;
  flex-direction: column;
  width: 260px;
  display: flex;
}

.utility-page-form {
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

.container-2 {
  z-index: auto;
  text-align: left;
  object-fit: scale-down;
  height: auto;
  margin-top: 0;
  padding-top: 10vh;
  display: block;
  position: absolute;
  top: 25vh;
  bottom: auto;
  left: 0%;
  right: 0%;
}

.container-2.container-lp-dsb {
  text-align: center;
}

.container-2.lp-dsb-2 {
  text-align: center;
  padding-top: 0;
  display: block;
  position: relative;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
}

.heading {
  color: var(--midnight-green-eagle);
  text-align: center;
  margin-top: 20px;
  padding-top: 10px;
}

.heading-2 {
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  background-color: #d782ff;
  margin-top: 20px;
  padding: 10px;
  font-size: 2em;
  font-weight: 700;
  line-height: 0.8em;
  display: inline-block;
}

.heading-feature-row {
  color: #0f4354;
  text-align: left;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.3em;
  display: block;
}

.heading-4 {
  color: #0f4354;
  text-align: left;
}

.color-blue {
  color: #0f4354;
  text-align: left;
  margin-top: 0;
  padding-top: 0;
  font-size: 1.2rem;
}

.color-blue.subheadermargintop70 {
  text-align: center;
  margin-top: 70px;
  padding-top: 10px;
}

.color-blue.margin30 {
  padding-top: 30px;
}

.color-blue.padding-10 {
  text-align: center;
  margin-top: 70px;
  padding-top: 10px;
}

.image {
  opacity: 1;
  -webkit-backdrop-filter: none;
  backdrop-filter: none;
  mix-blend-mode: normal;
}

.image-2,
.image-3 {
  -webkit-backdrop-filter: saturate(55%);
  backdrop-filter: saturate(55%);
}

.image-row {
  margin-top: 70px;
  margin-bottom: 40px;
}

.main-content {
  color: var(--midnight-green-eagle);
  padding-top: 100px;
  padding-bottom: 100px;
}

.heading-5 {
  margin-top: 10px;
  margin-bottom: 20px;
}

.sticky-nav {
  z-index: 1;
  background-color: #f4f4f4;
  padding: 20px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.nav-grid {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-rows: auto;
  grid-template-columns: 1fr max-content max-content max-content;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
  display: grid;
}

.nav-logo-link {
  height: 60px;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  display: block;
}

.nav-logo {
  object-fit: contain;
  object-position: 0% 50%;
  width: auto;
  height: 100%;
}

.nav-link-2 {
  color: #444;
  margin-left: 0;
  margin-right: 0;
  padding: 10px 0;
  text-decoration: none;
  display: block;
}

.nav-link-2:hover {
  text-decoration: underline;
}

.container-3,
.container-4 {
  margin-top: 50px;
}

.section-2 {
  margin-top: 74px;
  margin-bottom: 0;
}

.heading-6 {
  margin-top: 50px;
}

.container-5 {
  min-height: 70vh;
}

.heading-7 {
  color: var(--midnight-green-eagle);
}

.image-4 {
  flex: 0 auto;
  align-self: center;
  position: relative;
}

.column-2 {
  object-fit: contain;
  min-height: 0%;
}

.div-block-2 {
  height: 70px;
}

.html-embed {
  outline-offset: 0px;
  outline: 3px #333;
  width: 100%;
  height: 800px;
  margin-top: 100px;
  transition: opacity 0.2s;
}

.link {
  color: #489fd9;
}

.field-label {
  outline-offset: 0px;
  color: var(--midnight-green-eagle);
  outline: 3px #0f4354;
  text-decoration: none;
}

.checkbox-label {
  opacity: 1;
  color: var(--midnight-green-eagle);
  text-shadow: none;
}

.text-field {
  border: 2px solid #5c615f;
  margin-top: 10px;
}

.field-label-2 {
  color: var(--midnight-green-eagle);
  text-align: justify;
  text-shadow: none;
  margin-bottom: 20px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.form {
  display: block;
}

.section-3 {
  background-color: var(--light-moose-green);
}

.form-block {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  text-align: center;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: block;
}

.container-6 {
  margin-bottom: 0;
  padding-top: 70px;
  padding-bottom: 70px;
}

.submit-button {
  background-color: var(--midnight-green-eagle);
  text-align: center;
  object-fit: fill;
  min-width: 150px;
  display: inline-block;
  position: static;
  left: auto;
  right: auto;
}

.column-3 {
  text-align: center;
}

.section-4 {
  margin-top: 157px;
  position: relative;
  bottom: 0;
}

.div-block-3 {
  margin-top: 70px;
  display: block;
}

.heading-8 {
  color: var(--midnight-green-eagle);
  text-align: center;
  margin-top: 0;
  font-weight: 700;
}

.div-block-4 {
  margin-top: 85px;
  margin-bottom: 35px;
}

.collection-item {
  border-radius: 0;
  margin-bottom: 30px;
  padding: 5px;
}

.blog-summary-subline {
  color: #979797;
  margin-top: 10px;
  font-size: 12px;
}

.blog-summary {
  text-align: justify;
  margin-left: 0;
}

.div-block-5 {
  display: flex;
}

.heading-9 {
  border-color: var(--midnight-green-eagle);
  color: var(--midnight-green-eagle);
  min-height: 60px;
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
}

.image-5 {
  float: left;
  clear: none;
  width: auto;
  height: auto;
  max-height: 100px;
  margin-bottom: 10px;
  margin-right: 10px;
  padding-bottom: 0;
  padding-right: 0;
}

.image-6 {
  margin-bottom: 15px;
}

.div-block-6 {
  border: 0px solid var(--midnight-green-eagle);
  margin: 4px;
  padding: 8px;
}

.text-block {
  color: #949494;
  font-size: 10px;
}

.link-block {
  text-decoration: none;
}

.div-block-7 {
  margin-top: 70px;
}

.blog-post-headline {
  border: 1px none var(--midnight-green-eagle);
  color: var(--midnight-green-eagle);
  margin-bottom: 60px;
  font-size: 3em;
  font-weight: 700;
  line-height: 1em;
}

.blog-post-image {
  text-align: left;
  margin-bottom: 60px;
}

.div-block-8 {
  margin-top: 70px;
}

.blog-sidebar-creation-date {
  color: var(--midnight-green-eagle);
  font-size: 12px;
}

.image-7 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.collection-item-2 {
  margin-bottom: 40px;
}

.div-block-9 {
  margin-top: 60px;
}

.heading-10 {
  color: var(--midnight-green-eagle);
}

.container-7 {
  margin-top: 85px;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.container-8 {
  margin-top: 70px;
}

.section-5 {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  background-color: var(--alice-blue);
  flex-direction: column;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-around;
  align-items: stretch;
  display: block;
}

.body {
  background-color: var(--alice-blue);
}

.heading-11,
.heading-12,
.heading-13,
.heading-14,
.heading-15,
.heading-16 {
  text-align: left;
}

.heading-17 {
  color: var(--midnight-green-eagle);
  text-align: left;
  text-transform: none;
  text-shadow: none;
  border: 0 #000;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 10px;
  font-size: 20px;
  line-height: 26px;
  text-decoration: none;
  display: inline;
}

.heading-18 {
  text-align: left;
}

.section-6 {
  margin-top: 0;
  padding-top: 70px;
  padding-bottom: 70px;
}

.dropdown,
.container-9 {
  text-align: center;
}

.text-block-2 {
  margin-bottom: 40px;
  font-size: 1em;
  line-height: 1.5em;
}

.text-block-3 {
  color: #000;
  margin-top: 10px;
  font-size: 1em;
  line-height: 1.4em;
  text-decoration: none;
}

.collection-list {
  margin-top: 60px;
  margin-bottom: 60px;
}

.image-8 {
  transition: opacity 0.2s;
}

.heading-19 {
  color: var(--midnight-green-eagle);
  margin-top: 20px;
  font-size: 1.3em;
  font-weight: 700;
  line-height: 1.2em;
  text-decoration: none;
}

.link-block-2 {
  text-decoration: none;
}

.heading-20 {
  color: var(--midnight-green-eagle);
}

.link-block-3 {
  text-decoration: none;
}

.text-block-4 {
  color: var(--midnight-green-eagle);
  text-decoration: none;
}

.all-h2-headings {
  text-align: left;
  font-size: 1em;
  line-height: 1.5em;
}

.field-label-3,
.text-field-2,
.submit-button-2,
.heading-21 {
  display: none;
}

.front-page-subtext {
  text-align: center;
  background-color: rgba(230, 230, 230, 0.7);
  margin-top: 60px;
  padding: 20px;
  font-size: 0.9em;
  line-height: 1em;
  display: block;
  overflow: visible;
}

.image-9 {
  float: left;
  clear: none;
  text-align: left;
  padding-top: 0;
  position: relative;
  top: 50%;
  bottom: auto;
  left: 0%;
  right: 0%;
}

.picture-bullet-privapi-logo {
  padding-top: 11px;
  padding-bottom: 11px;
}

.heading-17a {
  color: var(--midnight-green-eagle);
  text-align: center;
  text-transform: none;
  text-shadow: none;
  border: 0 #000;
  margin-top: 0;
  margin-bottom: 20px;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  font-size: 1.2em;
  font-weight: 500;
  line-height: 1.3em;
  text-decoration: none;
  display: block;
}

.div-block-10 {
  text-align: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.heading-22 {
  color: var(--midnight-green-eagle);
  text-align: center;
  text-shadow: none;
  margin-top: 40px;
  font-size: 54px;
  line-height: 54px;
}

.bold-text-2 {
  font-size: 1.3em;
  line-height: 1em;
}

.text-block-5 {
  text-align: center;
  text-shadow: none;
  font-size: 1.2em;
  line-height: 1.2em;
  display: block;
}

.text-block-5.link-green,
.link-block-green {
  color: var(--midnight-green-eagle);
}

.paragraph-3,
.body-text-feature-row {
  text-align: left;
  font-size: 1em;
  line-height: 1.5em;
}

.text-block-6 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 1em;
  line-height: 1.6em;
}

.heading-23 {
  background-color: var(--goldenrod);
  color: var(--midnight-green-eagle);
  margin-bottom: 40px;
  padding: 5px 15px;
  font-size: 1.3em;
  line-height: 1.2em;
  display: inline-block;
}

.heading-23.highlight {
  background-color: var(--goldenrod);
  color: var(--midnight-green-eagle);
}

.container-10 {
  text-align: center;
}

.container-11 {
  text-align: center;
  font-size: 1em;
  line-height: 1em;
}

.paragraph-4,
.columns-3 {
  text-align: left;
}

.container-12 {
  text-align: center;
}

.collection-item-3 {
  text-align: left;
  margin-bottom: 40px;
}

.container-13,
.container-14,
.column-5 {
  text-align: center;
}

.body-3 {
  background-color: var(--midnight-green-eagle);
}

.container-15 {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.section-7 {
  margin-top: 8rem;
}

.heading-24 {
  color: var(--alice-blue);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  font-weight: 700;
  display: flex;
}

.text-block-7 {
  color: var(--alice-blue);
  text-align: center;
}

.container-16 {
  flex-direction: column;
  display: flex;
}

.btnvcf1 {
  border: 1px solid var(--alice-blue);
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 10px;
  order: 0;
  align-self: center;
  min-width: 400px;
  max-width: 400px;
  margin-top: 20px;
}

.heading-feature-paragraph {
  font-size: 1em;
  line-height: 1.6em;
}

.paragraph-6 {
  font-size: 1.2px;
}

@media screen and (max-width: 991px) {
  .button.hollow {
    width: 100%;
    margin: 10px auto;
    position: relative;
  }

  .button.in-pricing {
    width: 80%;
    font-size: 14px;
    line-height: 18px;
    overflow: auto;
  }

  .button.in-pricing.basefeebutton {
    object-fit: fill;
    object-position: 50% 50%;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 23px;
    display: block;
    position: absolute;
    top: auto;
    bottom: 0%;
    left: 0%;
    right: 0%;
    overflow: visible;
  }

  .button.free-flow-button-center-midnigh-green {
    width: 80%;
    font-size: 14px;
    line-height: 18px;
    overflow: auto;
  }

  .button.free-flow-button-center-midnigh-green.basefeebutton {
    object-fit: fill;
    object-position: 50% 50%;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 23px;
    display: block;
    position: absolute;
    top: auto;
    bottom: 0%;
    left: 0%;
    right: 0%;
    overflow: visible;
  }

  .logo-text {
    letter-spacing: 1.5px;
  }

  .nav-link {
    color: #fff;
    padding: 18px 0;
  }

  .nav-link:hover {
    box-shadow: inset 0 3px rgba(0, 0, 0, 0);
  }

  .nav-link.w--current {
    box-shadow: inset 0 1px rgba(0, 0, 0, 0);
  }

  .nav-link.menu {
    color: #000;
    padding: 22px 17px;
    font-size: 30px;
  }

  .nav-link.menu.w--open {
    color: #fff;
    background-color: #000;
  }

  .section.main {
    background-position: 50%;
    background-size: auto 110%;
  }

  .section.main.lp-dsb-2 {
    background-size: auto 100vh, auto;
  }

  .main-subtitle {
    margin-bottom: 10px;
  }

  .nav-menu {
    background-color: rgba(48, 48, 48, 0.96);
    padding-top: 13px;
    padding-bottom: 13px;
  }

  .main-heading.smaller-bottom-margin.two-part-top {
    font-size: 2.2em;
  }

  .plan-wrapper {
    height: 570px;
  }

  .main-feature-group {
    margin-top: 0;
  }

  .main-image {
    margin-top: 49px;
  }

  .columns {
    margin-bottom: 190px;
  }

  .heading-5 {
    margin-top: 20px;
  }

  .image-5 {
    object-fit: contain;
    width: auto;
    height: auto;
    max-height: 50px;
  }

  .bold-text-2 {
    font-size: 1em;
  }

  .body-2 {
    font-size: 1em;
    line-height: 1em;
  }
}

@media screen and (max-width: 767px) {
  h3 {
    margin-bottom: 8px;
  }

  .button.in-pricing,
  .button.in-pricing.basefeebutton,
  .button.free-flow-button-center-midnigh-green,
  .button.free-flow-button-center-midnigh-green.basefeebutton {
    bottom: 40px;
  }

  .logo-text {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .logo-text.footer {
    text-align: center;
    object-fit: fill;
    justify-content: center;
    align-items: stretch;
    width: 100%;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    position: static;
  }

  .nav-link.menu {
    margin-top: 13px;
    padding-top: 11px;
    padding-bottom: 11px;
  }

  .section {
    text-align: center;
    padding-top: 43px;
    padding-bottom: 43px;
  }

  .section.main {
    background-position: 50%;
    margin-top: 51px;
  }

  .section.main.lp-dsb-2 {
    margin-top: 65px;
  }

  .nav-menu {
    padding-left: 12px;
    padding-right: 12px;
  }

  .footer-title {
    margin-top: 35px;
  }

  .newsletter-form {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .main-heading.smaller-bottom-margin {
    line-height: 1em;
  }

  .main-heading.smaller-bottom-margin.two-part-top {
    font-size: 1.8rem;
    line-height: 1em;
  }

  .main-heading.smaller-bottom-margin.two-part-bottom {
    font-size: 36px;
  }

  .section-subtitle {
    margin-bottom: 44px;
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 0;
    padding-right: 0;
  }

  .plan-wrapper {
    height: 500px;
    margin-bottom: 34px;
    margin-left: 5%;
    margin-right: 5%;
  }

  .addl-feature {
    text-align: left;
  }

  .divider {
    width: 100%;
  }

  .feature {
    text-align: center;
    margin-bottom: 32px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .feature-icon {
    margin-bottom: 4px;
  }

  .main-feature-group {
    text-align: center;
    margin-top: 0;
    margin-bottom: 34px;
  }

  .column {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: block;
  }

  .columns {
    padding-left: 10px;
    padding-right: 10px;
  }

  .container-2 {
    padding-left: 0.25em;
    padding-right: 0.25em;
  }

  .heading-feature-row,
  .heading-4,
  .color-blue {
    text-align: center;
  }

  .main-content {
    padding: 40px 20px;
  }

  .sticky-nav {
    position: relative;
  }

  .nav-grid {
    text-align: center;
  }

  .paragraph {
    padding-left: 10px;
    padding-right: 10px;
  }

  .column-4 {
    display: none;
  }

  .heading-11,
  .heading-12,
  .heading-13,
  .heading-14,
  .heading-15,
  .heading-18 {
    text-align: center;
  }

  .bold-text-2 {
    font-size: 2rem;
  }

  .body-2 {
    font-size: 16px;
    line-height: 14px;
  }

  .paragraph-3,
  .body-text-feature-row {
    text-align: center;
  }

  .heading-23.highlight {
    background-color: var(--burlywood-dark);
  }

  .paragraph-4 {
    text-align: center;
  }
}

@media screen and (max-width: 479px) {
  .button.hollow {
    clear: none;
    flex: 1;
    justify-content: center;
    width: 100%;
    min-width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    padding-right: 0;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.2rem;
    display: block;
    position: static;
    overflow: hidden;
  }

  .button.button-newsletter {
    object-fit: fill;
    object-position: 50% 50%;
    min-width: 12em;
    max-width: 80%;
    height: 2.5em;
    min-height: 0;
    max-height: 8em;
    padding: 0;
    font-size: 14px;
    line-height: 18px;
    display: inline-block;
    position: static;
    overflow: visible;
  }

  .logo-text {
    font-weight: 500;
  }

  .nav-link.menu {
    flex: 0 auto;
    align-self: auto;
    margin-top: 13px;
    display: flex;
  }

  .section.main {
    background-color: #f4c47f;
    background-image: url('../images/pexels-amina-filkins-5560553.jpg'),
      linear-gradient(315deg, var(--goldenrod), #f0c380);
    background-position: 50%, 0 0;
    background-repeat: no-repeat, repeat;
    background-size: auto 100%, auto;
    background-attachment: fixed, scroll;
  }

  .section.main.lp-normal {
    object-fit: fill;
    flex: 1;
    align-self: auto;
    min-height: 50em;
    overflow: hidden;
  }

  .section.main.lp-dsb-2 {
    background-image: url('../images/p-light.png'),
      linear-gradient(315deg, var(--goldenrod), #f0c380);
    background-position: 50% 20%, 0 0;
    background-size: 150vw, auto;
    margin-top: 65px;
  }

  .brand-link.w--current {
    margin: auto;
  }

  .main-heading {
    letter-spacing: 1px;
  }

  .main-heading.smaller-bottom-margin {
    text-align: center;
    letter-spacing: 1px;
    margin-top: 0.25em;
    margin-bottom: 0.25em;
    padding: 0.5em 1em;
    font-size: 1.2em;
    font-weight: 900;
    line-height: 1em;
  }

  .main-heading.smaller-bottom-margin.two-part-top {
    white-space: normal;
    object-fit: fill;
    margin-left: 5px;
    margin-right: 5px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 1.1em;
    line-height: 1.2em;
  }

  .main-heading.smaller-bottom-margin.two-part-bottom {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 25px;
    line-height: 30px;
  }

  .main-heading.smaller-bottom-margin.align-left {
    margin-top: 0.25em;
    margin-bottom: 0.25em;
    padding: 0.5em 1em;
    font-size: 1.2em;
    font-weight: 900;
    line-height: 0.7em;
  }

  .feature-row {
    margin-bottom: 0;
  }

  .main-feature-group {
    text-align: center;
  }

  .client-logo {
    width: 33.33%;
  }

  .columns {
    top: 41px;
  }

  .container-2 {
    height: 1vh;
    margin-top: 0;
    font-size: 1.2em;
    line-height: 0.7em;
    top: 3%;
    bottom: auto;
    left: 0%;
    right: 0%;
    overflow: visible;
  }

  .container-2.lp-dsb-2 {
    flex: 0 auto;
    align-self: flex-start;
    margin-top: 12rem;
    position: relative;
  }

  .heading-2 {
    object-fit: scale-down;
    font-size: 1.2em;
    font-weight: 900;
    line-height: 1.2em;
    overflow: hidden;
  }

  .heading-feature-row,
  .heading-4,
  .color-blue {
    text-align: center;
  }

  .sticky-nav {
    padding: 0;
  }

  .nav-grid {
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    grid-template-rows: 100px;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }

  .nav-logo-link {
    border-top: 1px solid #c4c4c4;
  }

  .nav-link-2 {
    border-top: 1px solid #c4c4c4;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .container-3 {
    margin-top: 50px;
  }

  .image-4 {
    clear: left;
    flex: 0 auto;
    align-self: center;
    display: block;
    position: static;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
  }

  .blog-post-headline {
    font-size: 1.5em;
  }

  .container-7 {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }

  .columns-2 {
    display: block;
  }

  .column-4 {
    display: none;
  }

  .body {
    background-color: #fff;
  }

  .heading-11,
  .heading-12,
  .heading-13,
  .heading-14,
  .heading-15,
  .heading-16,
  .heading-18 {
    text-align: center;
  }

  .all-h2-headings {
    text-align: left;
  }

  .front-page-subtext {
    background-color: rgba(230, 230, 230, 0.9);
    margin-left: 5px;
    margin-right: 5px;
    font-size: 1.5em;
    line-height: 1.5em;
  }

  .heading-17a {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.2em;
  }

  .div-block-10 {
    text-align: center;
    width: auto;
    height: auto;
    padding-left: 5px;
    padding-right: 5px;
    display: block;
  }

  .heading-22 {
    margin-top: 10px;
    font-size: 1em;
    line-height: 1em;
  }

  .bold-text-2 {
    font-size: 1.4em;
    font-weight: 700;
    line-height: 2rem;
  }

  .text-block-5 {
    font-family: Roboto, sans-serif;
    font-size: 0.6em;
    font-weight: 500;
    line-height: 0.8em;
  }

  .text-block-5.link-green {
    font-size: 1rem;
    font-weight: 700;
    line-height: 0.8rem;
  }

  .link-block-green,
  .link-block-green:hover {
    font-size: 1.1em;
  }

  .text-block-6 {
    font-size: 1em;
    line-height: 1.6em;
  }

  .heading-23 {
    margin-left: 1em;
    margin-right: 1em;
  }

  .section-7 {
    margin-top: 4rem;
  }

  .heading-24 {
    margin-bottom: 0;
    font-size: 1.5rem;
    line-height: 3rem;
  }

  .text-block-7 {
    color: var(--alice-blue);
    text-align: center;
    font-size: 0.8rem;
    line-height: 1.1rem;
  }

  .container-16 {
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    display: flex;
  }

  .btnvcf1 {
    border: 1.5px solid var(--alice-blue);
    object-fit: fill;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 10px;
    flex: 0 auto;
    align-self: center;
    min-width: 70vw;
    max-width: 80vw;
    margin-top: 1rem;
  }

  .image-10 {
    width: 7rem;
  }
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto Slab';
  src: url('../fonts/RobotoSlab-VariableFont_wght.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto Condensed';
  src: url('../fonts/RobotoCondensed-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Arbutus Slab';
  src: url('../fonts/ArbutusSlab-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Medium.ttf') format('truetype'),
    url('../fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
